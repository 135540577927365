import React, { useEffect, useState } from "react";
import { data } from "../../data/constants";

const MainSection=()=>{
    let [count, setCount]=useState(1);
    let [count1, setCount1]=useState(1);
    useEffect(()=>{
        const interval =setInterval(()=>{
            console.log(count); 
            setCount((count)=>{return count===3?1:count+1});
             background(count);
        },4000);
        return () => clearInterval(interval);
    },[count]);
    const background=(circle)=>{
        // setCount((count)=>{return (count===3?1:count+1)})
        if(circle===1){
            document.getElementsByClassName("main-section-background")[0].style.backgroundImage=data.mainSection.backgroundImg1;
            // document.getElementsByClassName("main-section-background")[0].style.backgroundImage='url("https://cdn.create.vista.com/api/media/medium/141696440/stock-photo-beautiful-girl-with-long-wavy-hair?token=")';
            document.getElementsByClassName("mainSection")[0].style.display="flex";
            document.getElementsByClassName("mainSection2")[0].style.display="none"; 
            document.getElementsByClassName("mainSection3")[0].style.display="none";             
            document.getElementById("circle1").style.backgroundColor="#FF3F6E"
            document.getElementById("circle2").style.backgroundColor="white";
            document.getElementById("circle3").style.backgroundColor="white"            
        }
        else if(circle===2){
            document.getElementsByClassName("main-section-background")[0].style.backgroundImage=data.mainSection.backgroundImg2;
            document.getElementsByClassName("mainSection")[0].style.display="none";
            document.getElementsByClassName("mainSection2")[0].style.display="block";
            document.getElementsByClassName("mainSection3")[0].style.display="none";  
            document.getElementById("circle2").style.backgroundColor="#FF3F6E"
            document.getElementById("circle1").style.backgroundColor="white"
            document.getElementById("circle3").style.backgroundColor="white"
        }
        else if(circle===3){
            document.getElementsByClassName("main-section-background")[0].style.backgroundImage=data.mainSection.backgroundImg3;
            document.getElementsByClassName("mainSection")[0].style.display="none";
            document.getElementsByClassName("mainSection2")[0].style.display="none";  
            document.getElementsByClassName("mainSection3")[0].style.display="block";  
            document.getElementById("circle3").style.backgroundColor="#FF3F6E"
            document.getElementById("circle2").style.backgroundColor="white"
            document.getElementById("circle1").style.backgroundColor="white"
            
        }
    }
    
    return(
       <> <div className="main-section-background fade-in" id="background">
        <div className="mainSection">
            <h1>{data.mainSection.heading[0]}</h1>
            <h3>{data.mainSection.description1[0]}</h3>
            <h3>{data.mainSection.description2}</h3>
            <div className="buttonDiv">
                <a className="button" href="https://play.google.com/store/apps?hl=en&gl=US" ><img className="apple-logo" src={data.mainSection.appleImg}/><h4>Get App</h4></a>
                <a className="button" href="https://play.google.com/store/apps?hl=en&gl=US"><img className="apple-logo"  src={data.mainSection.androidImg}/><h4>Get App</h4></a>
            </div>
        </div>
        <div className="mainSection2 mainSection">
            <h1>{data.mainSection.heading[1]}</h1>
            <h3>{data.mainSection.description1[1]}</h3>
            
            
        </div>
        <div className="mainSection3 mainSection ">
            <h1>{data.mainSection.heading[2]}</h1>
            <h3>{data.mainSection.description1[2]}</h3>
            
           
        </div>
        </div>
         <div className="circles">
         <button className="circle" onClick={()=>background(1)} id="circle1"></button>
         <button className="circle" onClick={()=>background(2)} id="circle2"></button>
         <button className="circle" onClick={()=>background(3)} id="circle3"></button>
     </div>
     </>
    )
}
export default MainSection;