import React from "react";
import { data } from "../../data/constants";
const Services=()=>{
    return(
        <div className="mainServices">
            <h1>{data.services.heading}</h1>
            <div className="cardContainer">
                {data.services.card.map((card)=>{
                        return(
                            <div className="card">
                                <div className="imgDiv"><img src={card.img}/></div>
                                <div className="cardDetails">
                                <div className="cardHeading">{card.heading}</div>
                                <div className="cardDescription">{card.description}</div>
                                </div>
                            </div>
                        )
                })}
            </div>
        </div>
    )
}
export default Services;