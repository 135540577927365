import React, { useState } from "react";
import { data } from "../../data/constants";
import Typewriter from "typewriter-effect";
const Testimonials=()=>{
    const [start, setstart] =useState("true");
    const hover=(i)=>{
        // document.getElementById("normalComment").style.display="none";
        document.getElementsByClassName("comment1")[i].style.display="none";
        document.getElementsByClassName("typewriter")[i].style.display="block";
        
        
        // document.getElementsByTagName("Typewriter")[i].start();
        // setstart(true);
    }
    const mouseLeave=(i)=>{
        document.getElementsByClassName("comment1")[i].style.display="block";
        document.getElementsByClassName("typewriter")[i].style.display="none";
        setstart(true);
    }
    return(
        <div className="testimonials">
            <div className="heading">{data.testimonials.heading}</div>
            <div className="description">{data.testimonials.description}</div>

            <div className="feedbackCardContainer">
                
                {
                    data.testimonials.card.map((card,index)=>{
                        return(
                            <div className="feedbackCard" onMouseLeave={()=>{mouseLeave(index)}} onMouseEnter={()=>{hover(index)}}>
                                <img src={card.img}/>
                                <div className="comment comment1" id="normalComment"><span className="span">"</span>{card.comment}<span className="span">"</span></div>
                                <div className="comment typewriter" >
                                    <div className="quotes" >"</div>
                                <Typewriter 
                    options={{
                        strings:card.comment,
                        autoStart:true,
                        loop:true,
                        deleteSpeed:0,
                        pauseFor:4000,
                        delay:40
                    }}
                    />
                    <div className="quotesend">"</div>
                                </div>
                                <div className="name">{card.name}<span>  {card.work}</span></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default Testimonials;