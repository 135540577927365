import React from "react";
import { data } from "../../data/constants";
const AboutUs=()=>{
    return(
        <div className="mainAboutUs">
            <div className="aboutHeader">
                <div className="heading1">{data.about.heading}</div>
                <div className="description">{data.about.description1}</div>
            </div>
            <div className="aboutUsBody">
                <div className="counting">
                    <div className="countCardContainer">
                    {data.about.network.map((card)=>{
                        return(
                            <div className="countCard">
                                <img src={card.img}/>
                                <div className="name">{card.name}</div>
                                <div className="count">{card.count}</div>
                            </div>
                        )
                    })}
                    </div>
                </div>
                <div className="aboutUsInfo">
                    <div className="heading2" >{data.about.heading}</div>
                    <div className="description2">{data.about.description2}</div>
                    <div className="contact">
                        <div className="contactHeading">{data.about.contact.heading}</div>
                        <div className="phone"><img src={data.about.contact.phoneImg}/><span>{data.about.contact.phone}</span></div>
                        <div className="mail"><img src={data.about.contact.mailImg}/><span>{data.about.contact.mail}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs;