import React from "react";
import { data } from "../../data/constants";

const Footer=()=>{
    return(
        <div className="footer">
            <div className="footerText">{data.footer.description}<a href="./privacy-policy.html">{data.footer.link}</a></div>
            <div className="socialIcon">
                <a href={data.footer.image.facebook}><img className="image" src={data.footer.socialIcon.facebook}/></a>
                <a href={data.footer.image.instagram}><img className="image" src={data.footer.socialIcon.instagram}/></a>
                <a href={data.footer.image.twitter}><img className="image" src={data.footer.socialIcon.twitter}/></a>
                <a href={data.footer.image.youtube}><img className="image" src={data.footer.socialIcon.youtube}/></a>
            </div>
        </div>
    )
}
export default Footer;