import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import ClientSection from './components/client-section';
import MainSection from './components/main-section';
import Services from './components/services';
import Testimonials from './components/testimonials';
import AboutUs from './components/about-us';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
     <Header></Header>
     <MainSection></MainSection>
     <ClientSection></ClientSection>
     <Services></Services>
     <Testimonials></Testimonials>
     <AboutUs></AboutUs>
     <Footer></Footer>
    </div>
  );
}

export default App;
