import React from "react";
import { data } from "../../data/constants";
const Header=()=>{

    return(
        <div className="header">
            <h2>{data.appName}</h2>
        </div>
    )
}
export default Header;