import React from "react";
import { data } from "../../data/constants";

const ClientSection=()=>{
    return(
        <div className="client-section">
            <div className="client-img">
                <div className="clientImgCollage">
                <img className="clientImg1" id="clientImg" src={data.client.img1}/>
                <img className="clientImg2" id="clientImg" src={data.client.img2}/>
                <img className="clientImg3" id="clientImg" src={data.client.img3}/>
                <img className="clientImg4" id="clientImg" src={data.client.img4}/>
                </div>
            </div>
            <div className="client-info">
                <h2>{data.client.heading}</h2>
                <h3>{data.client.description1}</h3>
                
                <div className="clientImgCollage">
                <img className="clientImg1" id="clientImg" src={data.client.img1}/>
                <img className="clientImg2" id="clientImg" src={data.client.img2}/>
                <img className="clientImg3" id="clientImg" src={data.client.img3}/>
                <img className="clientImg4" id="clientImg" src={data.client.img4}/>
                </div>
                <h4>{data.client.description2}</h4>
            </div>
        </div>
    )
}
export default ClientSection;