export const data={
    appName:"Ziggly",
    mainSection:{
        heading:["Get The Perfect Look","With the Best Service","From the Professionals"],
        description1:["We make sure you find the best salon near you","As per the requirement, feedbacks and location ","We assure you highly proffessionals with great Exprience"],
        description2:"Download our app now !",
        
        backgroundImg1:'url("https://cdn.create.vista.com/api/media/medium/141696440/stock-photo-beautiful-girl-with-long-wavy-hair?token=")',
        backgroundImg2:'url("https://images.pexels.com/photos/853427/pexels-photo-853427.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")',
        backgroundImg3:'url("https://images.pexels.com/photos/1813272/pexels-photo-1813272.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")',
        appleImg:"https://img.icons8.com/ios-glyphs/30/FFFFFF/mac-os.png",
        androidImg:"https://img.icons8.com/fluency/48/google-play.png"
    },
    client:{
        img1:"https://cdn.create.vista.com/api/media/small/166639584/stock-photo-handshake",
        img2:"https://cdn.create.vista.com/api/media/medium/22928632/stock-photo-business-people-handshake?token=",
        img3:"https://st.depositphotos.com/1760420/4036/i/450/depositphotos_40369015-stock-photo-man-writing-happy-client-on.jpg",
        img4:"https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        heading:"Why Our Clients Choose Us", 
        description1:"We offer a seamless experience, helping you find the perfect salon tailored to your needs quickly and efficiently",
        description2:"Choose us for hassle-free salon discovery with our user friendly interface, comprehensively"
    },
    services:{
        heading:"Our Services",
        card:[
            {
                img:"https://cdn.create.vista.com/api/media/medium/231316840/stock-photo-young-female-hairstylist-handsome-smiling-man-looking-mirror-beauty-salon?token=",
                heading:"Haircut",
                description:"Get the Best Haircut from the Proffessionals"
            },
            {
                img:"https://images.pexels.com/photos/3985329/pexels-photo-3985329.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                heading:"Skin Care",
                description:"We take care of your skin and beauty"
            },
            {
                img:"https://images.pexels.com/photos/887352/pexels-photo-887352.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                heading:"Nail Treatment",
                description:"Nail care ,nail art and goot times "
            },
            {
                img:"https://images.pexels.com/photos/3764568/pexels-photo-3764568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                heading:"Massages",
                description:" therapies that will refresh your body and mind"
            }
        ]   
    },
    testimonials:{
        heading:"Testimonials",
        description:"Some of the Feedback of our regular user",
        card:[
            {
                img:"https://cdn.create.vista.com/api/media/medium/232628018/stock-photo-close-smiling-female-dentist-dental-clinic?token=",
                comment:"I loved the way website provide a easy and user friendly search approach for listing made my search stress free and very easy. I also liked the reviews section which helped me to choose the best salon for my needs.",
                name:"Nat Reynolds",
                work:"Co-founder of xyz."
            },
            {
                img:"https://cdn.create.vista.com/api/media/medium/364450750/stock-photo-smiling-handsome-guy-isolated-on-white-background?token=",
                comment:"i just wanted to express my appreciation for your website! It was really helpful for finding the salon as per my requirements. I really liked that salon and it was good experience. Thank you ziggly.",
                name:"Mary Lucas",
                work:"Manager at xyz."
            },
            {
                img:"https://cdn.create.vista.com/api/media/medium/278137206/stock-photo-redhead-woman-yellow-sweater-smiling?token=",
                comment:"your platform simpplified my salon search process, offering a diverse range of options as per my preferences.The good layout and semamless navigation enhanced my overall experience. ",
                name:"Ann Nelson",
                work:"Founder of xyz."
            },
            {
                img:"https://cdn.create.vista.com/api/media/medium/250363326/stock-photo-smiling-attractive-woman-white-sweater-looking-camera-isolated-pink?token=",
                comment:"This application madse finding a salon super easy.Loved how straightforward it was to use and the listing helped me to pick the best salon near me . Keep up the good work!",
                name:"Ann Nelson",
                work:"Founder of xyz."
            }
            
        ]
    },
    about:{
        heading:"About Us.",
        description1:"Our Network clients and contact Information",
        network:[
            {img:"https://img.icons8.com/color/48/businessman.png",
             name:"Users",
             count:"1100+"
            },
            {img:"https://img.icons8.com/stickers/100/group-of-projects.png",
             name:"Salons",
             count:"232+"
            },
            {img:"https://img.icons8.com/color/48/prize.png",
             name:"Appointments",
             count:"621+"
            },
            {img:"https://img.icons8.com/color/48/commercial-development-management.png",
             name:"Team",
             count:"17+"
            }
        ],
        description2:"Welcome to Ziggly,At Ziggly we understand the importance of feeling confident and pampered when visiting salon.That's why we've created a platform dedicated to simplifying the salon discovery process for you. Our aim is to connect you to top-notch salon that cater to your unique preferences and needs. Whether you're searching for trendy hair salon,a beauty expert,a relaxing spa or a skilled nail technician, we've got you covered.Our interface allows you to effortlessly browse through a wide range of salon.",
        contact:{
            heading:"Contact Information :",
            phoneImg:"https://img.icons8.com/ios-filled/50/ringer-volume.png",
            mailImg:"https://img.icons8.com/material-sharp/24/mail.png",
            phone:"+91 9876543210",
            mail:"Ziggly@mail.com"
        }
    },
    footer:{
        description:"@ 2024  Ziggly ",
        link:".Privacy Policy",
        socialIcon:{
            facebook:"https://img.icons8.com/color/48/facebook-new.png",
            instagram:"https://img.icons8.com/fluency/48/instagram-new.png",
            twitter:"https://img.icons8.com/fluency/48/twitter.png",
            youtube:"https://img.icons8.com/color/48/youtube-play.png"
        },
        image:{
            facebook:"https://www.facebook.com/,",
            instagram:"https://www.instagram.com/",
            twitter:"https://www.twitter.com/",
            youtube:"https://www.youtube.com/"
        }
    }

}